/* eslint-disable no-shadow */
// eslint-disable-next-line no-shadow
/* eslint-disable no-unused-vars */

export enum EUrlParam {
  TYPE = 'type',
  DEAL_TYPE = 'dealType',
  MODELS = 'models',
  MODEL = 'model',
  CATEGORIES = 'categories',
  COLORS = 'colors',
  BRANDS = 'brands',
  BRAND = 'brand',
  PAYMENT_METHOD = 'paymentMethod',
  MIN_PRICE = 'minPrice',
  MAX_PRICE = 'maxPrice',
  SELECTED_PLAN = 'selectedPlan',
  MAX_YEAR = 'maxYear',
  MIN_YEAR = 'minYear',
  MIN_KM = 'minKm',
  MAX_KM = 'maxKm',
  GEAR = 'gear',
  FUEL_TYPE = 'fuelType',
  TOGGLE_VALUE = 'toggleValue',
  HIGHLIGHTS = 'highlights',
  BODY_TYPES = 'bodyTypes',
  CAR_HAND = 'carHand',
  ORIGIN_OWNERSHIP = 'originOwnership',
  ONLY_WITH_IMAGES = 'onlyWithImages',
  LOCATION = 'location',
  PARAMS = 'params',
  CAR_FLOW_ID = 'carFlowId',
  COMMUNITY = 'community',
  BLOG_POST = 'post',
  CHECKOUT_ID = 'checkoutId',
  LABEL_PATH = 'labelPath',
  LOCATION_TYPE = 'location-type',
  AREA = 'area',
  ID = 'id',
  COLOR = 'color',
  FINISH_LEVEL = 'finishLevel',
  BUSINESS_LINE = 'businessLine',
  PLAN = 'plan',
  DEAL = 'deal',
  PAYMENT = 'payment',
  OR_YAROK = 'or-yarok',
}
